body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}
input[type=file] {
  width: 100%;
}

.App {
  margin: 3vh auto;
  min-height: 94vh;
}

.App .navbar-brand {
  font-weight: bold;
}
.Home .lander {
  padding: 80px 0;
  text-align: center; }

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600; }

.Home .lander p {
  color: #999; }

.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
  }
  @-webkit-keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
  }
  @keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
  }
@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0; }
  .Login form {
    margin: 0 auto;
    max-width: 320px; } }

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0; }
  .Login form {
    margin: 0 auto;
    max-width: 320px; } }

.NotFound {
  padding-top: 100px;
  text-align: center; }

@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0; }
  .Signup form {
    margin: 0 auto;
    max-width: 320px; } }

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999; }

@media all and (min-width: 480px) {
  .ResetPassword {
    padding: 60px 0; }
  .ResetPassword form {
    margin: 0 auto;
    max-width: 320px; }
  .ResetPassword .success {
    max-width: 400px; } }

.ResetPassword .success {
  margin: 0 auto;
  text-align: center; }

.ResetPassword .success .glyphicon {
  color: grey;
  font-size: 30px;
  margin-bottom: 30px; }

.Notes form {
  padding-bottom: 15px; }

.Notes form textarea {
  height: 300px;
  font-size: 24px; }

.Method form {
  padding-bottom: 15px; }

.Method form textarea {
  height: 300px;
  font-size: 24px; }

.Methods .lander {
  padding: 80px 0;
  text-align: center; }

.Methods .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600; }

.Methods .lander p {
  color: #999; }

.Methods .methods h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis; }

.Methods .methods p {
  color: #666; }

.NewMethod form {
  padding-bottom: 15px; }

.NewMethod form textarea {
  height: 300px;
  font-size: 24px; }

.Role form {
  padding-bottom: 15px; }

.Role form textarea {
  height: 300px;
  font-size: 24px; }

.NewRole form {
  padding-bottom: 15px; }

.NewRole form textarea {
  height: 300px;
  font-size: 24px; }

.Roles .lander {
  padding: 80px 0;
  text-align: center; }

.Roles .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600; }

.Roles .lander p {
  color: #999; }

.Roles .roles h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis; }

.Roles .roles p {
  color: #666; }

.Users .lander {
  padding: 80px 0;
  text-align: center; }

.Users .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600; }

.Users .lander p {
  color: #999; }

.Users .userLeft {
  align-self: auto; }

.Users .userRight {
  float: right; }

.Users .users h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis; }

.Users .users p {
  color: #666; }

.NewUser form {
  padding-bottom: 15px; }

.NewUser form textarea {
  height: 300px;
  font-size: 24px; }

.NewUser .formAddress {
  padding-left: 40px;
  padding-top: 5px; }

.User form {
  padding-bottom: 15px; }

.User form textarea {
  height: 300px;
  font-size: 24px; }

.User .formAddress {
  padding-left: 40px;
  padding-top: 5px; }




