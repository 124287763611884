.User form {
  padding-bottom: 15px;
}

.User form textarea {
  height: 300px;
  font-size: 24px;
}

.User .formAddress {
  padding-left: 40px;
  padding-top: 5px;
}