.NewUser form {
  padding-bottom: 15px;
}
  
.NewUser form textarea {
  height: 300px;
  font-size: 24px;
}

.NewUser .formAddress {
  padding-left: 40px;
  padding-top: 5px;
}